import React from 'react'
import Image from 'next/image'

import Container from 'react-bootstrap/Container'
import Menu from './partials/menu/Menu'
import MobileMenu from './partials/menu/mobile/Menu'
import Link from 'next/link'

const TopNavBar = () => (
  <>
    <div className="top-nav-bar">
      <Container className="h-100">
        <div className="top-nav-bar_inner">
          <div className="top-nav-bar_logo zx-transform-scale-11 zx-brightness-120 zx-transition-03">
            <Link href="/wow/private-servers">
              <Image width={150} height={50} src='/images/logo.svg' alt="Logo" />
            </Link>
          </div>
          <Menu />
          <MobileMenu />
        </div>
      </Container>
    </div>
  </>
)

export default TopNavBar
