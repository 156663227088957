import React, { useState } from 'react'
import { FaAngleUp } from 'react-icons/fa'
import Link from 'next/link'

interface MenuItemProps {
  title: string
  url?: string
  items?: Map<string, any>
}

const MenuItem = ({ title, url, items }: MenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  return <>
    <div className={'mobile-menu_item' + (isOpen ? ' mobile-menu_item-active' : '')}>
      <div className="mobile-menu_item_text" onClick={onToggle}>
        { url && (
          <span className={'mobile-menu_item_text_label' + (isOpen ? ' mobile-menu_item-active_text_label' : '')}><Link href={url} legacyBehavior>{ title }</Link></span>
        )}
        { !url && (
          <span className={'mobile-menu_item_text_label' + (isOpen ? ' mobile-menu_item-active_text_label' : '')}>{ title }</span>
        )}

        { items && items.size && <FaAngleUp className={'mobile-menu_item_text_icon' + (isOpen ? ' mobile-menu_item_text_icon-active' : '')} /> }
      </div>

      { items && items.size && isOpen && (
        <div className="mobile-menu_item_dropdown">
          { Array.from(items).map(([key, value]) => (
            <div className="mobile-menu_item_dropdown_item" key={key}>
              <MenuItem title={key} url={value.size ? '' : value} items={value} />
            </div>
          )) }
        </div>
      )}
    </div>
  </>
}

export default MenuItem
