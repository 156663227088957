import apiClient from '@/api/client'
import { ServerReviews, ServerReviewVotes, User } from '@prisma/client'

const PREFIX = 'review'

const URL = {
  ADD: `${PREFIX}/add`,
  VOTE: `${PREFIX}/vote`,
  REPORT: `${PREFIX}/report`
}

interface IAddRequest {
  title: string
  content: string
  rating: number | string
  parentId?: number
}

export interface IAddReviewResponse {
  data: ServerReviews & {
    ServerReviewVotes: ServerReviewVotes[]
    User: User
    totalUpVotes: number
    totalDownVotes: number
    slug: string
    hasReported: boolean
    canReply: boolean
    parentId?: number
    reply?: any
  }
  message: string
}

export interface IAddResponse {
  id: number
  message: string
}

export interface IVoteRequest {
  serverId: number
  reviewId: number
  vote: boolean
}

interface IReportRequest {
  serverId: number
  reviewId: number
  reason: string
  type: string
  contactable: boolean
}

export default {
  add: async (serverId: string | number, data: IAddRequest): Promise<IAddReviewResponse> => {
    return await apiClient.post(URL.ADD, {
      id: serverId,
      ...data
    }).then(res => res.data)
  },
  vote: async (data: IVoteRequest): Promise<IAddResponse> => {
    return await apiClient.post(URL.VOTE, data).then(res => res.data)
  },
  report: async (data: IReportRequest) => {
    return await apiClient.post(URL.REPORT, data).then(res => res.data)
  }
}
