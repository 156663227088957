import apiClient from '@/api/client'
import { QueryParameters } from '@/pages/api/servers'
import { Servers } from '@prisma/client'

const PREFIX = 'servers'

const URL = {
  SERVERS: `${PREFIX}`,
  SET_FAVOURITE: `${PREFIX}/favourite`,
  SET_SERVER_IMPRESSSIONs: `${PREFIX}/impressions`
}

interface IServersResponse {
  query: Partial<{
      [key: string]: string | string[];
  }>;
  servers: Servers[];
  totalPages: number;
  currentPage: number;
}

interface IFavouriteServerResponse {
  success: boolean;
  message: string;
}

interface ISetServerImpressionsResponse {
  success: boolean;
}

export default {
  /**
   * Get a list of servers based on the provided query parameters.
   *
   * @param {QueryParameters} query - The query parameters to filter the servers.
   * @returns {Promise<IServersResponse>} - A promise that resolves to an object containing the servers, query parameters, and pagination information.
   */
  getServers: async (query: QueryParameters = {}) : Promise<IServersResponse> => {
    return await apiClient.get(URL.SERVERS, { params: query }).then(res => res.data)
  },

  /**
   * Set the impressions count for the specified servers.
   *
   * @param {number[]} serverIds - An array of server IDs to set the impressions count for.
   * @returns {Promise<ISetServerImpressionsResponse>} - A promise that resolves to an object indicating whether the operation was successful.
   */
  setServerImpressions: async (serverIds: number[]) : Promise<ISetServerImpressionsResponse> => {
    return await apiClient.post(URL.SET_SERVER_IMPRESSSIONs, { serverIds }).then(res => res.data)
  },

  /**
   * Set the specified server as a favorite.
   *
   * @param {number} serverId - The ID of the server to set as a favorite.
   * @returns {Promise<IFavouriteServerResponse>} - A promise that resolves to an object indicating whether the operation was successful.
   */
  setFavorite: async (serverId: number) : Promise<IFavouriteServerResponse> => {
    return await apiClient.post(URL.SET_FAVOURITE, { serverId }).then(res => res.data)
  }
}
