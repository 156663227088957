import apiClient from '@/api/client'
import { News } from '@prisma/client'

const PREFIX = 'auth'

const URL = {
  NEWS: `${PREFIX}/news/`,
  SERVER_NEWS: 'servers/server-news'
}

interface INewsRequest {
  slugTitle: string
}

interface INewsResponse {
  id: number,
  userId: number,
  serverId: number,
  title: string,
  slugTitle: string,
  content: string,
  imageUrl: string,
  createdAt: string,
  updatedAt: string
}

interface IServerNewsResponse {
  serverNews: News[]
}

export default {
  getNews: async (data: INewsRequest) : Promise<INewsResponse> => {
    return await apiClient.get(`${URL.NEWS}${data.slugTitle}`).then(res => res.data)
  },
  getServerNews: async (data: INewsRequest) : Promise<IServerNewsResponse[]> => {
    return await apiClient.get(URL.SERVER_NEWS, { params: data }).then(res => res.data)
  }
}
