import apiClient from '@/api/client'
import { QueryParameters } from '@/pages/api/addons'
import { Addons } from '@prisma/client'

const PREFIX = 'addons'

const URL = {
  ADDONS: `${PREFIX}`,
  DOWNLOAD: `${PREFIX}/download`
}

interface IAddonsResponse {
  query: Partial<{
    [key: string]: string | string[];
  }>;
  addons: Addons[];
  totalPages: number;
  currentPage: number;
}

export default {
  /**
   * Retrieves a list of addons with optional query parameters.
   * @async
   * @param {QueryParameters} query - Optional query parameters to filter the list of addons.
   * @returns {Promise<IAddonsResponse>} - A Promise that resolves with the list of addons and pagination information.
   */
  getAddons: async (query: QueryParameters = {}): Promise<IAddonsResponse> => {
    return await apiClient.get(URL.ADDONS, { params: query }).then(res => res.data)
  },
  /**
   * Downloads an addon with the specified ID.
   * @async
   * @param {number} id - The ID of the addon to download.
   * @returns {Promise<any>} - A Promise that resolves with the downloaded addon data.
   */
  download: async (id: number): Promise<any> => {
    return await apiClient.post(URL.DOWNLOAD, {
      id
    }).then(res => res.data)
  }
}
