import { ArticleComments, Articles, User } from '@prisma/client'
import apiClient from '@/api/client'
import { QueryParameters } from '@/pages/api/article/articles'

const PREFIX = 'article'

const URL = {
  ARTICLES: `${PREFIX}/articles`,
  ADD: `${PREFIX}/add`,
  VOTE: `${PREFIX}/vote`,
  COMMENTVOTE: `${PREFIX}/commentvote`
}

interface IArticlesResponse {
  query: Partial<{
      [key: string]: string | string[];
  }>;
  articles: Articles[];
  totalPages: number;
  currentPage: number;
}

interface IAddRequest {
  content: string
  parentId?: number
  replyId?: number
}

export interface IAddResponse {
  averageRating: number
}

export interface IVoteRequest {
  articleId: number
  userId: number
  articleRating: number
}

export interface IAddCommentResponse {
  data: ArticleComments & {
    User: User
  }
  message: string
}

export interface IVoteCommentRequest {
  articleId: number
  articleCommentsId: number
  vote: boolean
}

export default {
  /**
   * Get a list of articles based on the provided query parameters.
   *
   * @param {QueryParameters} query - The query parameters to filter the articles.
   * @returns {Promise<IServersResponse>} - A promise that resolves to an object containing the articles, query parameters, and pagination information.
   */
  articles: async (query : QueryParameters = {}) : Promise<IArticlesResponse> => {
    return await apiClient.get(URL.ARTICLES, { params: query }).then(res => res.data)
  },
  add: async (articleId : number, data: IAddRequest) : Promise<IAddCommentResponse> => {
    return await apiClient.post(URL.ADD, {
      articleId,
      ...data
    }).then(res => res.data)
  },
  vote: async (data: IVoteRequest) : Promise<IAddResponse> => {
    return await apiClient.post(URL.VOTE, data).then(res => res.data)
  },
  commentvote: async (data: IVoteCommentRequest) : Promise<IAddResponse> => {
    return await apiClient.post(URL.COMMENTVOTE, data).then(res => res.data)
  }
}
