import apiClient from '@/api/client'

const PREFIX = 'auth'

const URL = {
  REGISTER: `${PREFIX}/register`,
  REFRESH_SESSION: `${PREFIX}/session?refresh`,
  VERIFY_EMAIL: `${PREFIX}/verify-email`,
  FORGOT_PASSWORD: `${PREFIX}/forgot-password`,
  RESET_PASSWORD: `${PREFIX}/reset-password`
}

interface IRegisterRequest {
  email: string
  password: string
  passwordConfirmation: string
  name: string
  agree: boolean,
  token: string | null
}

interface IRegisterResponse {
  accessToken: string
  expiresIn: number
  refreshToken: string
}

interface IRefreshSessionResponse {
  success: boolean
}

interface IVerifyEmailResponse {
  success: boolean
  status: string
}

interface IForgotPasswordResponse {
  success: boolean
  message: string
}

interface IResetPasswordResponse {
  success: boolean
  message: string
}

export default {
  register: async (data: IRegisterRequest) : Promise<IRegisterResponse> => {
    return await apiClient.post(URL.REGISTER, data).then(res => res.data)
  },
  refreshSession: async () : Promise<IRefreshSessionResponse> => {
    return await apiClient.get(URL.REFRESH_SESSION).then(res => res.data)
  },
  verifyEmail: async (token: string, email: string) : Promise<IVerifyEmailResponse> => {
    return await apiClient.get(`${URL.VERIFY_EMAIL}?token=${token}&email=${email}`).then(res => res.data)
  },
  forgotPassword: async (email: string) : Promise<IForgotPasswordResponse> => {
    return await apiClient.post(URL.FORGOT_PASSWORD, { email }).then(res => res.data)
  },
  resetPassword: async (token: string | null | undefined, password: string, passwordConfirmation: string) : Promise<IResetPasswordResponse> => {
    return await apiClient.post(URL.RESET_PASSWORD, { token, password, passwordConfirmation }).then(res => res.data)
  }
}
