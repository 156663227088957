import apiClient from '@/api/client'
import { Invoices } from '@prisma/client'
const PREFIX = 'portal'

const URL = {
  ADD_SERVER: `${PREFIX}/owner/servers/add`,
  DELETE_SERVER: `${PREFIX}/owner/servers/delete`,
  PAUSE_SERVER: `${PREFIX}/owner/servers/pause`,
  UPDATE_SERVER_REALM: `${PREFIX}/owner/servers/update/realm`,
  UPDATE_SERVER_LOGO: `${PREFIX}/owner/servers/update/logo`,
  UPDATE_SERVER_DETAILS: `${PREFIX}/owner/servers/update/details`,
  SERVERS: `${PREFIX}/owner/servers`,
  SAVE_NEWS: `${PREFIX}/owner/news/save`,
  UPLOAD_IMAGE: `${PREFIX}/images/upload`,
  FAVOURITE_SERVERS: `${PREFIX}/owner/servers/favourite`,
  NEWS: `${PREFIX}/owner/news`,
  CREATE_ORDER: `${PREFIX}/paypal/create-order`,
  CAPTURE_ORDER: `${PREFIX}/paypal/capture-order`,
  INVOICES: `${PREFIX}/owner/invoices`,
  CANCEL_ORDER: `${PREFIX}/paypal/cancel-order`
}
interface IUpdateServerResponse {
  id: number,
  message?: string
}

interface IUpdateServerDetailsRequest {
  onlineSince: string,
  population: string,
  realmLanguage: string,
  realmRates: string,
  realmList: string,
  websiteUrl: string,
  realmFeatures: string[],
  agree: boolean
}

interface IUpdateServerLogoRequest {
  videoUrl: string,
  imageUrl: string
}

interface ISaveNewsRequest {
  id?: number,
  title: string
  content: string
  serverId: number,
  imageUrl: string
}

interface IUploadImageRequest {
  fileName: string,
  fileType: string,
  file: File,
  folder: string
}

interface ISaveNewsResponse {
  id: number,
  slugTitle: string
}

interface IUploadImageResponse {
  url: string,
  fields: any
}

interface IGetServersRequest {
  type: string
}

interface ICaptureOrderRequest {
  orderId: string
  type: string
  id: number
}

interface IGetServersResponse {
  agree: boolean,
  createdAt: string,
  description: string,
  discordUrl: string,
  draft: boolean,
  expansion: string,
  id: number,
  imageUrl: string,
  onlineSince: string,
  population: string,
  realmFeatures: string,
  realmLanguage: string,
  realmList: string,
  realmRates: string,
  realmType: string,
  realmStatus: string,
  statusType: string,
  title: string,
  updatedAt: string,
  userId: string,
  videoUrl: string,
  websiteUrl: string
  isFeatured: boolean
}

interface IDeleteServerResponse {
  success: boolean
  message: string
}

export default {
  getServers: async (data : IGetServersRequest): Promise<IGetServersResponse[]> => {
    const response = await apiClient.get(URL.SERVERS, {
      params: {
        type: data.type
      }
    })
    return response.data
  },
  deleteServer: async (id: number): Promise<IDeleteServerResponse> => {
    const response = await apiClient.delete(URL.DELETE_SERVER, {
      params: {
        id
      }
    })

    return response.data
  },
  pauseServer: async (id: number, pause: boolean): Promise<IUpdateServerResponse> => {
    const response = await apiClient.post(URL.PAUSE_SERVER, {
      id,
      pause
    })

    return response.data
  },
  addServer: async () : Promise<IUpdateServerResponse> => {
    return await apiClient.post(URL.ADD_SERVER).then(res => res.data)
  },
  updateServerRealm: async (id: number, data: any) : Promise<IUpdateServerResponse> => {
    return await apiClient.post(URL.UPDATE_SERVER_REALM, { id, ...data }).then(res => res.data)
  },
  updateServerLogo: async (id: number, data: IUpdateServerLogoRequest) : Promise<IUpdateServerResponse> => {
    return await apiClient.post(URL.UPDATE_SERVER_LOGO, { id, ...data }).then(res => res.data)
  },
  updateServerDetails: async (id: number, data: IUpdateServerDetailsRequest) : Promise<IUpdateServerResponse> => {
    return await apiClient.post(URL.UPDATE_SERVER_DETAILS, { id, ...data }).then(res => res.data)
  },
  saveNews: async (data: ISaveNewsRequest) : Promise<ISaveNewsResponse> => {
    // Make sure it's a number
    data.serverId = parseInt(data.serverId.toString())

    return await apiClient.post(URL.SAVE_NEWS, data).then(res => res.data)
  },
  uploadImage: async (data: IUploadImageRequest) : Promise<any> => {
    // File cannot be more than 4mb
    // This will appear 'An Error has occured, please try again later' as the error message
    if (data.file.size > 4000000) {
      throw new Error('File cannot be more than 4mb')
    }

    const { url, fields } = await apiClient.post(URL.UPLOAD_IMAGE, {
      fileName: data.fileName,
      fileType: data.fileType,
      folder: data.folder
    }).then(res => res.data) as IUploadImageResponse

    const formData = new FormData()
    for (const key in fields) {
      formData.append(key, fields[key])
    }

    formData.append('file', data.file)

    // Upload the file
    await apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    })

    // Image Url to the uploaded image
    const imageUrl = `${url}${fields.key}`

    return {
      url: imageUrl
    }
  },
  favouriteServers: async () : Promise<any> => {
    return await apiClient.get(URL.FAVOURITE_SERVERS).then(res => res.data)
  },
  news: async () : Promise<any> => {
    return await apiClient.get(URL.NEWS).then(res => res.data)
  },
  createOrder: async (type: string, id: number) : Promise<{orderId : string}> => {
    const response = await apiClient.post(URL.CREATE_ORDER, {
      type,
      id
    }).then(res => res.data)

    return response
  },
  captureOrder: async (data: ICaptureOrderRequest) : Promise<any> => {
    const response = await apiClient.post(URL.CAPTURE_ORDER, data).then(res => res.data)

    return response
  },
  invoices: async (data?: {
    search: string,
    type: string,
    status: string
  }) : Promise<Invoices[]> => {
    return await apiClient.get(URL.INVOICES, {
      params: data
    }).then(res => res.data)
  },
  cancelOrder: async (orderId: string) : Promise<void> => {
    await apiClient.post(URL.CANCEL_ORDER, {
      orderId
    }).then(res => res.data)
  }
}
