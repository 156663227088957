import apiClient from '@/api/client'

const PREFIX = 'contact'

const URL = {
  CONTACT: `${PREFIX}/contact`
}

interface IContactRequest {
  subject: string
  content: string
  email: string
}

interface IContactResponse {
  message: string
}

export default {
  addCase: async (data: IContactRequest) : Promise<IContactResponse> => {
    return await apiClient.post(URL.CONTACT, data).then(res => res.data)
  }
}
