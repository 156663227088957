import apiClient from '@/api/client'
import { AdBanners } from '@prisma/client'

const PREFIX = 'banners'

const URL = {
  retrieve: `${PREFIX}`
}

export default {
  getBanners: async () : Promise<AdBanners[]> => {
    return await apiClient.get(URL.retrieve).then(res => res.data)
  }
}
