import routes from 'lib/routes'

export const privateServerMenu = new Map([
  ['Vanilla WoW Servers', routes.Expansions.Vanilla],
  ['TBC Private Servers', routes.Expansions.TBC],
  ['WotLK Private Servers', routes.Expansions.WotLK],
  ['Cataclysm Private Servers', routes.Expansions.Cataclysm],
  ['MOP Private Servers', routes.Expansions.MOP],
  ['WOD Private Servers', routes.Expansions.WOD],
  ['Legion Private Servers', routes.Expansions.Legion],
  ['BFA Private Servers', routes.Expansions.BFA],
  ['Shadowlands Private Servers', routes.Expansions.Shadowlands],
  ['Dragonflight Private Servers', routes.Expansions.Dragonflight]
])

export const addonMenu = new Map([
  ['Vanilla WoW Addons', routes.AddonExpansions.Vanilla],
  ['TBC WoW Addons', routes.AddonExpansions.TBC],
  ['WoW 3.3.5 Addons', routes.AddonExpansions.WotLK],
  ['Cataclysm Addons', routes.AddonExpansions.Cataclysm],
  ['MOP Addons', routes.AddonExpansions.MOP],
  ['WOD Addons', routes.AddonExpansions.WOD],
  ['Legion Addons', routes.AddonExpansions.Legion],
  ['BFA Addons', routes.AddonExpansions.BFA],
  ['Shadowlands Addons', routes.AddonExpansions.Shadowlands],
  ['Dragonflight Addons', routes.AddonExpansions.Dragonflight]
])

const wowClients = new Map([
  ['Vanilla WoW 1.12.1 Client', '/blog/vanilla-wow-client-download'],
  ['TBC Client 2.4.3 Download', '/blog/tbc-2-4-3-client-download-wow-burning-crusade-client'],
  ['Wotlk 3.3.5 Client Download', '/blog/wotlk-3-3-5-client-download-wrath-of-the-lich-king-client'],
  ['Cataclysm 4.3.4 Client Download', '/blog/wow-cataclysm-4-3-4-client-download'],
  ['Mop WoW 5.4.8 Client', '/blog/5-4-8-mop-client'],
  ['Legion 7.3.5 Client', '/blog/legion-client'],
  ['BFA WoW Client', '/blog/bfa-client'],
  ['Shadowlands WoW Client', '/blog/shadowlands-client-9-0-x-client-for-shadowlands']
])
const iteratorWoWClients = wowClients.entries()

const wowNews = new Map([
  ['Server News', '/server-news']
])
const iteratorWowNews = wowNews.entries()

const wowRepacks = new Map([
  ['Wotlk Repack', '/blog/wotlk-repack-a-wow-3-3-5-repack-for-wrath-of-the-lich-king'],
  ['Cataclysm Repack', '/blog/cataclysm-repack'],
  ['MoP 5.4.8 Repack', '/blog/mop-repack'],
  ['WoW Legion Repack', '/blog/legion-repack'],
  ['Shadowlands Repack', '/blog/shadowlands-wow-repack']
])
const iteratorWoWRepacks = wowRepacks.entries()

const wowEmulation = new Map([
  ['WoW GM Commands', '/blog/wow-gm-commands'],
  ['Making your own WoW Server', '/blog/how-to-make-your-own-wow-private-server']
])
const iteratorWoWEmulation = wowEmulation.entries()

const wowGuides = new Map([
  ['TBC Leveling Guide', '/blog/wow-tbc-leveling-guide'],
  ['Dragonflight Leveling Guide', '/blog/dragonflight-leveling-guide-wow']
])
const iteratorWoWGuides = wowGuides.entries()

const wowExpansions = new Map([
  ['Cataclysm', '/blog/cataclysm-wow']
])
const iteratorWoWExpansions = wowExpansions.entries()

// TODO: Can we make an automatic iterator instead of .next().value?
export const wowSection = new Map([
  ['WoW News', new Map([
    iteratorWowNews.next().value // Server News
  ])
  ],
  ['WoW Clients', new Map([
    iteratorWoWClients.next().value, // Vanilla Client
    iteratorWoWClients.next().value, // TBC Client
    iteratorWoWClients.next().value, // Wotlk Client
    iteratorWoWClients.next().value, // Cataclysm Client
    iteratorWoWClients.next().value, // Mop Client
    iteratorWoWClients.next().value, // Legion Client
    iteratorWoWClients.next().value, // BFA Client
    iteratorWoWClients.next().value // Shadowlands Client
  ])
  ],
  ['WoW Repacks', new Map([
    iteratorWoWRepacks.next().value, // Wotlk Repack
    iteratorWoWRepacks.next().value, // Cataclysm Repack
    iteratorWoWRepacks.next().value, // MoP Repack
    iteratorWoWRepacks.next().value, // Legion Repack
    iteratorWoWRepacks.next().value // Shadowlands Repack
  ])
  ],
  ['WoW Emulation', new Map([
    iteratorWoWEmulation.next().value, // GM Commands
    iteratorWoWEmulation.next().value // Own WoW Server
  ])
  ],
  ['WoW Leveling', new Map([
    iteratorWoWGuides.next().value, // TBC Leveling Guide
    iteratorWoWGuides.next().value // Dragonflight Leveling Guide
  ])
  ],
  ['WoW Expansions', new Map([
    iteratorWoWExpansions.next().value // Cataclysm
  ])
  ]
])
