import React, { useEffect, useState } from 'react'
import MenuItem from './MenuItem'
import { FaBars, FaTimes } from 'react-icons/fa'
import { privateServerMenu, wowSection, addonMenu } from '../MenuLinksData'
import { Router } from 'next/router'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)

  // Watch for route changes and close the menu
  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setIsOpen(false)
    })

    return () => {
      Router.events.off('routeChangeComplete', () => {
        setIsOpen(false)
      })
    }
  }, [])

  return (
    <>
      <div className="mobile-menu">
        <div className="mobile-menu_icon">
          {isOpen ? <FaTimes onClick={() => setIsOpen(!isOpen)} /> : <FaBars onClick={() => setIsOpen(!isOpen)} />}
        </div>
        <div className={'mobile-menu_content' + (isOpen ? ' mobile-menu_content-open' : '')}>
          <div className="mobile-menu_content_inner">
            <MenuItem title="WoW Private Servers" items={privateServerMenu} />
            <MenuItem title="WoW Addons" items={addonMenu} />
            <MenuItem title="WoW Section" items={wowSection} />
            <MenuItem title="Blog" url="/blog" />
            <MenuItem title="Advertising" url="/advertising" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu
