import apiClient from '@/api/client'

const URL = {
  search: '/search'
}

export default {
  getSearch: async (input : string, searchType : string) : Promise<any[]> => {
    return await apiClient.post(URL.search, {
      input,
      searchType
    }).then(res => res.data)
  }
}
