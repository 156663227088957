import apiClient from '@/api/client'

const PREFIX = 'portal'

const URL = {
  UPDATE_PROFILE_INFO: `${PREFIX}/profile/update/info`,
  UPDATE_PROFILE_ROLE: `${PREFIX}/profile/update/role`,
  UPDATE_PROFILE_PASSWORD: `${PREFIX}/profile/update/password`
}

interface IUpdateProfileInfoRequest {
  name: string;
  email: string;
  position: string;
  aboutMe: string;
}

interface IUpdateProfileRoleRequest {
  role: string;
}

interface IUpdateProfilePasswordRequest {
  password: string;
  confirmPassword: string;
}

export interface IUpdateProfileInfoResponse {
  success: boolean;
  user: {
    id: number;
    email: string;
    name: string;
    role: string;
  }
  message: string;
}

export default {
  updateInfo: async (data: IUpdateProfileInfoRequest) : Promise<IUpdateProfileInfoResponse> => {
    return await apiClient.post(URL.UPDATE_PROFILE_INFO, data).then(res => res.data)
  },
  updateRole: async (data: IUpdateProfileRoleRequest) : Promise<IUpdateProfileInfoResponse> => {
    return await apiClient.post(URL.UPDATE_PROFILE_ROLE, data).then(res => res.data)
  },
  updatePassword: async (data: IUpdateProfilePasswordRequest) : Promise<IUpdateProfileInfoResponse> => {
    return await apiClient.post(URL.UPDATE_PROFILE_PASSWORD, data).then(res => res.data)
  }
}
