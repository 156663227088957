import React, { useState, useRef, useEffect } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import MultiMenuDropdown from './MultiMenuDropdown'
import SingleMenuDropdown from './SingleMenuDropdown'

interface MenuItemProps {
  menu: any
  title: string
  url: string
  accordion?: boolean
  multiLevel: boolean
}

const MenuItemDropdown = ({ menu, title, url, accordion, multiLevel }: MenuItemProps) => {
  const [open, setOpen] = React.useState(false)
  const [childOpen, setChildOpen] = React.useState(false)
  const [childOpenId, setChildOpenId] = React.useState('')
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleParentOpen = () => {
    setOpen(true)
    setOpacityStyle(1)
  }
  const handleParentLeave = () => {
    setOpen(false)
    setOpacityStyle(0)
  }
  const handleChildOpen = (e: any) => {
    setChildOpenId(e.target.id)
    setChildOpen(true)
  }
  const handleChildLeave = () => {
    setChildOpen(false)
  }

  const [opacityStyle, setOpacityStyle] = useState(0)

  const closeDropdown = () => {
    handleParentLeave()
    handleParentLeave()
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      // Clicked outside the dropdown, so close it
      closeDropdown()
    }
  }

  // Handle outer mouse clicks to remove the dropdown if clicked out of the element
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <>
      <div className="menu-item-container" ref={dropdownRef}>
        <div className="menu_item_text d-flex align-items-center" onMouseEnter={handleParentOpen}>
          <a className="zx-text-link-white zx-btn-blue:hover" href={url}>
            {title}
          </a>
          {accordion && <FaAngleDown className="menu_item_icon" />}
        </div>
        <div className={'dropdown_menu_item' + (open ? ' menu-border' : '')} style={{ opacity: opacityStyle }}>
          {open
            ? (
              <ul className="menu-dropdown" onMouseLeave={handleParentLeave}>
                {/* Links has a key value pair (Title and URL) */}
                {Array.from(menu.entries()).map((menuItem) => {
                  const [menuTitle, menuLink] = menuItem as [string, string]
                  if (multiLevel === false) {
                    return (
                      <SingleMenuDropdown key={menuTitle} menuTitle={menuTitle} menuLink={menuLink} />
                    )
                  } else {
                    return (
                      <MultiMenuDropdown key={menuTitle} menu={menu} menuTitle={menuTitle}
                        menuLink={menuLink} childOpen={childOpen} childOpenId={childOpenId}
                        handleChildOpen={handleChildOpen} handleChildLeave={handleChildLeave} />
                    )
                  }
                })}
              </ul>)
            : null
          }
        </div>
      </div>
    </>
  )
}

export default MenuItemDropdown
