import React from 'react'
import { FaAngleDown } from 'react-icons/fa'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface MenuItemProps {
  title: string
  url: string
  accordion?: boolean
}

const MenuItem = ({ title, url, accordion }: MenuItemProps) => {
  const isActive = (url: string) => {
    if (url === useRouter().pathname) {
      return true
    }
    return false
  }

  return <>
    <div className={'menu_item' + (isActive(url) ? ' menu_item-active' : '')}>
      <span className="menu_item_text"><Link href={url} legacyBehavior>{ title }</Link></span>
      { accordion && <FaAngleDown className="menu_item_icon" /> }
    </div>
  </>
}

export default MenuItem
