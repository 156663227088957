import React from 'react'
import { Container } from 'react-bootstrap'

const CopyRight = () => (
  <>
    <div className="copy-right">
      <Container className="h-100">
        <div className="copy-right_inner h-100">
          <div className="copy-right_text">
          &copy; All Rights Reserved || Zremax || 2024
          </div>
        </div>
      </Container>
    </div>
  </>
)

export default CopyRight
