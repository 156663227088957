import React from 'react'

interface IProps {
  menuTitle: string
  menuLink: string
}

const SingleMenuDropdown = ({ menuTitle, menuLink } : IProps) => {
  return (
      <li key={menuTitle} className="menu-item" >
        <a className="zx-text-link-white menu-link zx-btn-blue:hover" href={menuLink}>
          {menuTitle}
          <span></span>
        </a>
      </li>
  )
}

export default SingleMenuDropdown
