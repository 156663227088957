import Link from 'next/link'
import React, { useState, useEffect, useRef } from 'react'
import { signIn, useSession } from 'next-auth/react'
import { Container } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import { Role } from '@prisma/client'
import SearchBar from '@/components/base/SearchBar'
import api from '@/api/index'

const SystemBar = () => {
  const { data: session } = useSession()
  const [showDropdown, setShowDropdown] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')
  const [searchedResults, setSearchedResults] = useState(Array)
  const [searchType, setSearchType] = useState('servers') // server value is default
  const dropdownRef = useRef<HTMLDivElement>(null)

  const myProfileLink = () => {
    if (session?.user) {
      if (session.user.role === Role.OWNER) {
        return '/portal/owner/servers'
      } else {
        return '/portal/saved-servers'
      }
    }

    return '/auth/signin'
  }

  const toggleDropdown = (e: any) => {
    // Determine if dropdown should be visible or invisible
    showDropdown === '' ? setShowDropdown('show') : setShowDropdown('')
  }

  const handleOnChange = async (event:React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setSearchInputValue(inputValue)
    let apiResponse
    const debounceTimer = 300
    // Only actual inputs will send a response (ie. to prevent API call if you went from 1 character to 0)
    if (inputValue.length > 0) {
      // Set a timeout to delay the API call by 1 second
      setTimeout(async () => {
        apiResponse = await api.search.getSearch(inputValue, searchType)
        setSearchedResults(apiResponse)
      }, debounceTimer)
    }
  }

  const closeDropdown = () => {
    const dropdownSelection = dropdownRef?.current?.querySelector('.dropdown_menu_item.search')
    if (dropdownSelection) {
      dropdownSelection.classList.remove('show')
      setShowDropdown('')
    }
    const searchBarSelection = dropdownRef?.current?.querySelector('.dropdown_menu_item.search-result')
    if (searchBarSelection) {
      searchBarSelection.classList.remove('show')
      setSearchInputValue('')
      setSearchedResults([])
    }
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      // Clicked outside the dropdown, so close it
      closeDropdown()
    }
  }

  // Handle outer mouse clicks to remove the dropdown if clicked out of the element
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return <>
    <div className="system-bar" ref={dropdownRef}>
      <Container className='h-100'>
        <div className="system-bar_inner h-100 justify-content-between">
          {!session?.user
            ? (
              <>
                <div className="system-bar_info">
                  Welcome to Zremax!
                </div>
                <SearchBar
                  toggleDropdown={toggleDropdown} showDropdown={showDropdown}
                  handleOnChange={handleOnChange} searchInputValue={searchInputValue}
                  searchedResults={searchedResults} searchType={searchType}
                  setSearchType={setSearchType} setShowDropdown={setShowDropdown} dropdownRef={dropdownRef}
                />
                <div className="ms-2 ms-md-0 d-flex flex-row justify-content-between">
                  <div className="nav-item d-flex discord-icon ms-0 me-4">
                    <a href="https://discord.gg/S6yffquAkA" className="text-white" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-discord" viewBox="0 0 16 16" fill="currentColor">
                      <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"></path>
                    </svg>
                    </a>
                  </div>
                  <div className="system-bar_login">
                    <FaUser className="system-bar_icon" />
                    <span className="system-bar_login_account hover-blue-color" onClick={() => signIn()}>Login</span> <span className="system-bar_login_splitter ">/</span> <Link href="/auth/register" className="hover-blue-color system-bar_login_account">Register</Link>
                  </div>
                </div>
              </>
              )
            : (
              <>
                <div className="system-bar_info">
                  Welcome to Zremax!
                </div>
                <SearchBar
                  toggleDropdown={toggleDropdown} showDropdown={showDropdown}
                  handleOnChange={handleOnChange} searchInputValue={searchInputValue}
                  searchedResults={searchedResults} searchType={searchType}
                  setSearchType={setSearchType} setShowDropdown={setShowDropdown} dropdownRef={dropdownRef}
                />
                <div className="d-flex flex-row justify-content-between">
                  <li className="nav-item d-inline-block discord-icon ms-0 me-4">
                    <a href="https://discord.gg/S6yffquAkA" className="text-white" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-discord" viewBox="0 0 16 16" fill="currentColor">
                      <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"></path>
                    </svg>
                    </a>
                  </li>
                  <Link href={myProfileLink()} legacyBehavior>
                    <div className="system-bar_profile">
                      <FaUser /> <div className="system-bar_profile_menu hover-blue-color">
                        Profile
                      </div>
                    </div>
                  </Link>
                </div>
              </>
              )}
        </div>
      </Container>
    </div>
  </>
}

export default SystemBar
