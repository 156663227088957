import authApi from './auth'
import addonApi from './addon'
import articleApi from './article'
import contactApi from './contact'
import portalApi from './portal'
import newsApi from './news'
import profileApi from './profile'
import reviewApi from './review'
import serverApi from './server'
import bannerApi from './banners'
import searchApi from './search'

export default {
  auth: authApi,
  addon: addonApi,
  article: articleApi,
  banner: bannerApi,
  contact: contactApi,
  portal: portalApi,
  news: newsApi,
  profile: profileApi,
  review: reviewApi,
  search: searchApi,
  server: serverApi
}
