import { Icon } from '@iconify/react'
import React from 'react'

interface IProps {
  toggleDropdown: any,
  setShowDropdown: any,
  showDropdown: any,
  handleOnChange: any,
  searchInputValue: any,
  searchedResults: any
  searchType: any,
  setSearchType: any,
  dropdownRef: any
}

const SearchBar = ({ toggleDropdown, setShowDropdown, showDropdown, handleOnChange, searchInputValue, searchedResults, searchType, setSearchType, dropdownRef }: IProps | any) => {
  const handleSearchTypeChange = (type: any) => {
    setSearchType(type)
    // Remove the dropdown menu after selection of type
    const signInElem = document.querySelector('.dropdown_menu_item.search.show')
    signInElem?.classList.remove('show')
    setShowDropdown('')
  }

  return (
    <div className="search-bar flex-grow-1 ms-0 me-0 me-md-5 me-lg-5 ms-lg-5 justify-content-center d-flex">
      <div className="search-bar-child d-flex w-100">
        <input onChange={handleOnChange} type="search" id="searchForm" className="form-control w-100 search-input" placeholder="Find resource..." />
        { /* Search result dropdown */}
        {searchInputValue?.length > 0 &&
          <div className={'dropdown_menu_item search-result ' + 'show'}>
            <div className="inner show dropdown-inside">
              <ul className="menu-dropdown search">
                { /* Result of the fetched servers */}
                {searchedResults?.length > 0 &&
                  (searchedResults?.map((result: any) => (
                    <li key={result?.id} className="menu-item" >
                      {/* For servers */}
                      {result?.title && result?.expansion &&
                        <a className="zx-text-link-white menu-link zx-btn-blue:hover" href={`/wow/private-servers/${result?.expansion.toLocaleLowerCase()}-private-servers/${result?.slugTitle}`}>
                          {result?.title}
                        </a>
                      }
                      {/* For articles */}
                      {result?.title && result?.content &&
                        <a className="zx-text-link-white menu-link zx-btn-blue:hover" href={`/blog/${result?.slugTitle}`}>
                          {result?.title}
                        </a>
                      }
                      {/* For users */}
                      {result?.name &&
                        <a className="zx-text-link-white menu-link zx-btn-blue:hover" href={`/profile/${result?.name}`}>
                          {result?.name}
                        </a>
                      }
                    </li>
                  )))}
              </ul>
              { /* TODO: Scroll button if height greater than X. TODO 2: Onmouseleave. */}
            </div>
          </div>
        }
        <div className="dropdown bootstrap-select form-control search-options position-absolute">
          <button type="button" onClick={(e) => toggleDropdown(e)} className="btn btn-primary btn-search w-100" title="servers">
            <div className="filter-option">
              <div className="filter-option-inner d-flex justify-content-center gap-1">
                <span className="filter-option-inner-inner">{searchType}</span>
                <Icon className="mt-1" icon="akar-icons:chevron-down" />
              </div>
            </div>
          </button>
          { /* Selection Dropdown */}
          <div className={'dropdown_menu_item search ' + showDropdown}>
            <div className="inner show dropdown-inside">
              <ul className="menu-dropdown search">
                <li key="servers" className={searchType === 'servers' ? 'menu-item active' : 'menu-item'}>
                  <span className="zx-text-link-white menu-link zx-btn-blue:hover" onClick={(() => handleSearchTypeChange('servers'))}>
                    Servers
                  </span>
                </li>
                <li key="guides" className={searchType === 'guides' ? 'menu-item active' : 'menu-item'}>
                  <span className="zx-text-link-white menu-link zx-btn-blue:hover" onClick={(() => handleSearchTypeChange('guides'))}>
                    Guides
                  </span>
                </li>
                <li key="users" className={searchType === 'users' ? 'menu-item active' : 'menu-item'}>
                  <span className="zx-text-link-white menu-link zx-btn-blue:hover" onClick={(() => handleSearchTypeChange('users'))}>
                    Users
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="head_search_mobi_close" id="close_search">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="bi bi-x-lg" viewBox="0 0 16 16" fill="currentColor">
            <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"></path>
            <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"></path>
          </svg>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="bi bi-search" viewBox="0 0 16 16" fill="currentColor">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
        </svg>
      </div>
    </div>
  )
}
export default SearchBar
