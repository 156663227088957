import axios, { AxiosRequestConfig } from 'axios'

const client = axios.create({
  baseURL: '/api'
})

client.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers === undefined) {
    config.headers = {}
  }

  return config
})

client.interceptors.response.use((response) => {
  return response
}, (error) => {
  return Promise.reject(error)
})

export default client
