import React from 'react'
import { FaAngleRight } from 'react-icons/fa'

interface IProps {
  menu: any
  menuTitle: string
  menuLink: string
  childOpen: boolean
  childOpenId: string
  handleChildOpen: (e: any) => void,
  handleChildLeave: () => void
}

const MultiMenuDropdown = ({ menu, menuTitle, menuLink, childOpen, childOpenId, handleChildOpen, handleChildLeave } : IProps) => {
  let childMenuData = menu.get(menuTitle)
  childMenuData = Array.from(childMenuData.entries())

  const parentMenuCorrector = (title: string, menuLinkValue: string) => {
    if (menuTitle === title) {
      menuLink = menuLinkValue
    }
  }

  // Hack to make parent menu links have URLs as well.
  parentMenuCorrector('WoW News', '#')
  parentMenuCorrector('WoW Clients', '/blog/wow-private-server-clients')
  parentMenuCorrector('WoW Repacks', '/blog/wow-repacks')
  parentMenuCorrector('WoW Leveling', '#')
  parentMenuCorrector('WoW Expansions', '#')
  parentMenuCorrector('WoW Emulation', '#')

  return (
    <li key={menuTitle} className="menu-item" onMouseEnter={handleChildOpen} onMouseLeave={handleChildLeave}>
      <a className="zx-text-link-white menu-link" href={menuLink}
        id={menuTitle}
        onMouseEnter={handleChildOpen}>
        {menuTitle}
        {<FaAngleRight className="menu_item_icon" />}
      </a>

      {childOpen && childOpenId === menuTitle
        ? (
            childMenuData &&
          <ul id={menuTitle} className="dropdown_menu_item_child ">
            { /* First index is menutitle, second index is link */}
            {childMenuData.map((childMenuEntry : any) => {
              return (
                <li className="menu_row_child" key={childMenuEntry[0]}>
                  <a className="menu-link zx-text-link-white" href={childMenuEntry[1]}>
                    {childMenuEntry[0]}
                    <span></span>
                  </a>
                </li>
              )
            })}
          </ul>
          )
        : null}
    </li>
  )
}

export default MultiMenuDropdown
